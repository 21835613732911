

$ec_input_lineHeight: 1.5;

button > * { //
    pointer-events: none;
}

.ec_btn-moreInfo { 
    .icon {
        @include icon_fontAwesome("\f05a") ;   
    }
}

.ec_btn-delete {
    .icon {
        @include icon_fontAwesome("\f2ed");   
    }
}

.ec_btn-edit {
    .icon {
        @include icon_fontAwesome("\f044");
    }
}
 
.ec_btn_primary { 
    line-height: $ec_input_lineHeight;
    width: 100%;
    padding: 16px 10px;
    position: relative;
    border: none;
    background-color: $color-primary;
    color: $color-secondary !important;
    text-transform: uppercase; 
    text-align: center;  
    cursor: pointer; 

    &:hover {
        text-decoration: none !important;
    }
}

.ec_btn_secondary {
    line-height: $ec_input_lineHeight;
    width: auto;
    padding: 7px 10px;
    position: relative;
    background-color: $color-secondary;
    color: $color-primary;
    border: 1px solid $color-primary;
    font-weight: 600;
    text-align: center;  
    cursor: pointer;
}

.btn_addToWishlist,
.btn_addToCart {

    &:hover {
        text-decoration: underline; 
    }
    &:focus{
      outline: none;    
    }
}

.btn_addToCart {
    background: $color-primary;
    color: $color-secondary;
    &:hover {
        color: $color-secondary;
    }

    .product-details__main & {
        padding-left: 25px;
        padding-right: 25px;
    }
}
 
.btn_addToWishlist {
    padding: 0;
    border: none;
    background-color: transparent;
    width: 100%;
    
    color: $dark-grey; 
    font-family: $font-primary;  
 
    .product-details & {
        width: auto;
        color: $color-primary;
    }

    .products-list & {
         
    }

    .icon {
        margin-right: 5px;
        @include icon_fontAwesome("\f004", $style:'regular');    
    }
 
    .testo2 {
        display: none;
    }

    &.inWishlist {
        .icon { 
            font-weight: 900;  
        }

        .testo {
            display: none;
        }

        .testo2 {
            display: inline;
        }
    }
}

.notSellable,
.isOutOfStock {
    //button-primary
//     margin-top: 8px;
//     margin-bottom: 8px;
    min-height: 45px;
    height: auto; 
    line-height: normal; //testo su due righe
    color: $color-primary;
    display: flex;
    align-items: center;

    .products-list-page & {
        margin-left: auto;
        margin-right: auto; 
        text-align: center;
        min-width: 1px; //let .notSellable occupy space
    }
}

.isOutOfStock {
    color: #640000;
    font-weight: 700;
    letter-spacing: .32px;
    line-height: 18px;
    padding: 0.375rem 0px;
    font-size: 16px;
    //padding: 10px;
    //margin-left: auto;
    //margin-right: auto;

    .products-list-page & {       
        font-size: inherit;
        text-align: center;
    }
}

.product-link__outOfStock{
    padding: .375rem 0;
}

.paddingSouthAfrica{
    padding: 15px 0 !important;
}

.product-details{
    .isOutOfStock{
        padding: unset;
    }
    .isOutOfStock__backInStock{
        font-family: $font-primary;
        font-weight: 400;
        letter-spacing: initial;
        color: $color-primary;
        line-height: 1.5;
    }
}