/* CART */

//common

@media (min-width: $tablet-P) {
    .wrapper{
        position: sticky;
    }
}

.price--strikethrough {
    color: $color-secondary--disabled;
    text-decoration: line-through;
}

.discount-applied {
    color: $dark-gold;
}

.ecoPartecipation-cart{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.checkout-column-main {
    .wrapper {
        @media (min-width: $tablet-L) { 
            margin-right: 5%;
        }
    }

    .product-count {
        font-weight: bold;
        text-align: right;
    }

    .cartProduct-list {
        margin-bottom: 20px;
    }

    p.error {
        line-height: 1.2rem;
        padding-top: 5px;
    }
    .label-qnt,
    .cartProduct-delete>span {
        font-size: .63rem;
        font-weight: 400;
    }
}

.checkout-column-aside {
    @media (max-width: $tablet-P) {
        order: 3;
    }
    > .wrapper {
        background-color: #f4f4f4;
        padding: 1rem;
        margin-bottom: 2rem;
        @media (max-width: $tablet-L) {
            margin-bottom: 0;
        }
    }

    .customerCareInfo-box-tel {
        margin-bottom: 20px;
    }

    .customerCareInfo {
        padding: 0 15px;
    }

    .ec_btn_primary {
        margin-bottom: 4.5rem;
    }
 
    .checkout-information {
        margin: 30px;
    }

    .pb-5 {
        padding-bottom: 0 !important;
    }

    // klarna-placement *::part(osm-container) {
    //     background-color: transparent;
    //     border: 1px solid;
    //     background: none;
    // }
    klarna-placement *::part(osm-container) {
        background-color: #F0EEEA;
        border: 0 none;
    }
}

.delivery-compatible {
    text-align:center; 
    background-color: $dusty-grey;
    margin-bottom: 10px;
    padding: 2px 8px; 

    .label {
        font-weight: bold; 
        color: $dark-gold;
        display:flex;
        line-height: 1.3rem;

        @media (max-width: $tablet-P) {
            text-align: left; 
            align-items: center;
        }
        @media (min-width: $tablet-P) {
            justify-content: center; 
            padding: 7px;
        }
    }

    .ec_btn-moreInfo { 
        font-size: inherit;
        color: $black;

        @media (max-width: $tablet-P) {
            order: -1;
            margin-right: 15px;
            margin-left: 0;
        }

         .icon {
            background-image: url('#{$images}/icons/ecommerce/icon-info.svg');
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            padding: 1px;
            margin-left: 8px;
            &:before {
                content:'';
            }
        }
    }
}

.cmp_cart {
    position: relative;
    .container {
        @media (max-width: $desktop) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .product-count.d-block {
        position: absolute;
        top: 58px;
        right: 15px;
        font-weight: 700;
    }
    .product-count.d-none {
        margin-bottom: 30px;
    }
}






// #region PRODUCT

.cartProduct { 
    border-bottom: 1px solid  $grey;
    padding: 10px 0 0 0; 
    margin-bottom: 10px;
    align-items: center;
}

.cartProduct-name,
.cartProduct-details,
.cartProduct-image,
.cartProduct-prices,
.cartProduct-selectQuantity, 
.cartProduct-delete {
    margin-bottom: 1.875rem;
}

.cartProduct-image {  
    text-align: center; 

    img {
        width: 100%;
    } 
}

.cartProduct-details {
    padding-left: 10px !important;
    //align-items: center;
    margin-bottom: 0;

    .focus {
        line-height: 22px;
    }
    .focus-info {
        font-weight: 400;
        line-height: normal;
        font-size: 0.75rem;
    }
}


.cartProduct-name {
    line-height: 1.5;
    font-weight: bold;
    font-size: 1.25rem; 

    @media (max-width: $desktop) {
        margin-bottom: 0;
    }

    @media (max-width: $tablet-P) {
        font-size: .875rem;
    }

    .SKU {
        margin-top: 10px;
        display: block;
        font-weight: normal;
        font-size: .875rem;

        @media (max-width: $tablet-P) {
            font-size: .625rem;
        }
    }
}


.cartProduct-selectQuantity {
    .ec_btn-increase.invisible {
        @media (max-width: $desktop) {
            display: none;
        }
    }
    .selected-quantity {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: none;
        border-radius: 0;
        width: 36px;
        border: 1px solid  $black;
        text-align: center;
        vertical-align: middle;
        font-size: .875rem;
        line-height: normal;
        height: auto;
    }

    .ec_btn {
        font-family: 'Mulish';
        font-size: 1rem;
        padding: 8px .75rem;
    }

    .label-qnt{
        @media (max-width: $tablet-P) {
            font-size: .625rem;
            font-weight: 400;
        }
    }
}

.cartProduct-delete {
    align-self: center;
    margin-left: 20px;

    @media (min-width: $desktop) {
        margin-left: auto;
    }
    @media (max-width: $desktop) {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        @media (max-width: $tablet-P) {
            font-size: .625rem;
        }
        //margin-bottom: 0 !important;
    }

    button {
        @media (max-width: $desktop) {
            padding-right: 0;
        }
    }
}

.cartProduct-prices {
    line-height: normal;
    text-align: right;

    .price { 
        font-size: 1.25rem;
        font-weight: 700;
        display: inline-block;
        line-height: normal;

        @media (max-width: $tablet-P) {
            font-size: .875rem;
        }
    }

    .price--strikethrough {
        font-size: 0.9rem;
        font-weight: normal;
        margin-right: 10px;
        line-height: normal;

        @media (max-width: $tablet-P) {
            display: block;
            margin-right: 0;
            font-size: .75rem;
        }
    }

    .discount-applied {
        font-weight: 100;
        line-height: normal;
        font-size: 0.875rem;
        @media (max-width: $tablet-P) {
            font-size: .625rem;
        }
        @media (min-width: $tablet-P) {
            margin-top: 10px;
        }
    }
}
 
.delivery-schedule {
    flex: 0 0 100%;
    margin-top: auto;
    line-height: 1.5;
    font-style: italic;
    color: $dark-grey;
}

.cartProduct-services {
    flex: 0 0 100%;

    .label {
        font-weight: bold;
    }

    .service,
    .service-description {
        padding: .375rem;
    }

    .service {
        background-color: $dusty-grey;
        margin-bottom: .375rem;  
        line-height: 1.5;

        &:last-child {
            margin-bottom: 0;
        }

        .service-header {
            display: flex; 
            align-items: center; 

            > .d-flex {
                max-width: 70%;
            }
        }

        label {
            margin-bottom: 0;
        }
        
        input[type=checkbox] {
            margin-top: .375rem;
            margin-right: .375rem;
            align-self: flex-start;
        }
 
        .service-description { 
            font-size: 0.9rem;
        }

        .ec_btn-moreInfo { 
            font-size: inherit;
            color: $black;
    
             .icon {
                background-image: url('#{$images}/icons/ecommerce/icon-info.svg');
                background-repeat: no-repeat;
                width: 20px;
                height: 20px;
                padding: 1px;
                margin-left: 8px;
                &:before {
                    content:'';
                }
            }
        }

    }

    .service-prices {
        position: relative;
        margin-left: auto;
        text-align: center;;

        .price { 
            font-size: 1rem;
            font-weight: bold;
            display: inline-block;
        }
    
        .price--strikethrough {
            color: $color-secondary--disabled;
            text-decoration: line-through;
            font-size: 0.9rem;
            font-weight: normal;
            margin-right: 10px; 
        }
    }
} 
// #endregion


// #region PROMOCODE

.promocodeForm {
    display: flex;
    flex-wrap: wrap;

    input,
    button {
        border-radius: 0;
        -webkit-appearance: none;
        margin-bottom: 0.5rem;
    }

    label {
        flex: 0 0 100%;
    }

    input {
        &[type="text"] {
            line-height: $ec_input_lineHeight;
            width: 100%;
            padding: 16px 10px;
            background-color: $color-secondary;
            border: 1px solid $light-grey;

            padding-top: 7px;
            padding-bottom: 7px;
            flex: 2 1 70%;
            min-width: 0;
        }

        &:disabled {
            border: 0;
        }
    }

    button {
        flex: 2 1 30%;
    }

}
// #endregion

// #region PRICERECAP
.checkout-priceRecap {
    margin-top: 30px; 

    .priceRecap-prices  {
        display: flex; 
        flex-wrap: wrap;
        margin-bottom: 10px;
        font-size: .75rem;

        @media (min-width: $tablet-P) {
            font-size: 1rem;  
        }
        
        .prices { 
            margin-left: auto;
            text-align: right;
        }

        .price {
            margin-left: 15px;
            display: inline-block;

            &:not(.price--strikethrough) {
                font-size: 1rem;     
                @media (min-width: $tablet-P) {
                    font-size: 1.25rem;  
                }
            }
        }
    }

    .priceRecap-label {
        margin-right: auto;
    }
 
    .priceRecap-total {
        display: flex; 
        flex-wrap: wrap;
        font-size: 1.5rem;
        font-weight: 700;
        margin: 10px 0 0 0;
        line-height: normal;


        .price {
            margin-left: auto; 
        }
 
 
        .discount-applied {
            font-size: .75rem;
            flex: 0 0 100%; 
            text-align: right;
            font-weight: 400;
        }
    }

    #checkout & {
        .priceRecap-total {
            margin: 3rem 0 1rem 0;
        }
    }
}
// #endregion

// #region BUTTONS 

.ec_btn-moreInfo { 
    padding-left: 0.2rem;
}

.ec_btn-delete, .ec_btn-edit {
    padding: .375rem .75rem;
}

.ec_btn-delete {
    padding-right: 0;
}

// #endregion 
.checkout-column-aside {
    button.primary {
        margin-top: 25px;
        margin-top: 30px;
        letter-spacing: .125rem;
    }
    .footerPartnership {
        margin: 30px 0;
    }
    .customareCare-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        .titolo {
            font-weight: 700;
            margin-bottom: 30px;
        }

        .customerCareInfo {
            margin-top: 30px;
        }
    }

    .customerCareInfo-box-tel {
        font-size: .875rem;
        .icon {
            margin-right: 1.3rem;
        }
    }

    .customerCareInfo-link-wrap {
        text-align: center;

        a{
            text-decoration: underline;
            margin-bottom: 20px;
            display: block;
        }
    }

    .customerCareInfo {
        width: 100%;
        padding: 0;

        [class*=customerCareInfo-box-] {
            margin-bottom: 0;
        }
    }

    .accordion-Ccare {
        width: 100%;
        button {
            color: $black;
            background: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            overflow: hidden;
            font-weight: 700;
            &:hover {
                text-decoration: none;
            }
            .icon {
                background: url('#{$images}/icons/ecommerce/icon-accordion.svg');
                width: 20px;
                height: 10px;
                background-repeat: no-repeat;
                transform: rotate(180deg);
                margin: 0;
            }
            &.collapsed {
                border-bottom: 1px solid #000;
                border-radius: 0;

                .icon {
                    transform: rotate(0);
                    translate: 50% 50%;
                }
            }
        }
        .collapse {
            border-bottom: 1px solid #000;
        }
    }

    .fixed-bar {
        background: #f8f8f8;
        display: flex !important;
        padding: 10px;
        justify-content: space-between;
        align-items: center;

        button{
            max-width: 47%;
            margin-bottom: 0;
            max-height: 50px;
            display: flex;
            align-items: center;
            margin-top: 0;
        }

        .priceRecap-total {
            font-weight: 700;
            line-height: 1.3rem;

            .discount-applied {
                font-size: .75rem;
                font-weight: 100;
            }
           
        }
    }
}


.checkout-information  {
    font-size: 1rem;
    line-height: normal;
    //display: flex;
    //justify-content: center;

    ul {
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 0;

        @media (max-width: 600px) {
            flex-direction: column;
        }
    }
    
    li {
        display: flex; 
        align-items: center;
        margin-bottom: 24px; 
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: flex-start;
        font-size: .75rem;
        font-weight: 400;
        line-height: 1rem;
        flex-grow: 1;
        width: 33%;
        text-align: center;
        padding: 0 10px;

        @media (max-width: 600px) {
            width: auto;
            flex-direction: row;
            font-weight: 400;
            text-transform: none;
        }

       &:last-child {
        margin-bottom: 0;
        }
    }

    .delivery,
    .return,
    .securepayment{
        .label{
            width: 100%;
            padding-left: 10px;
            text-transform: uppercase;
            font-size: 12px;
            @media (min-width: $desktop) and (max-width: $desktop-XL) {
                font-size: 10px;
            }
        }
    }

    .icon {
        font-size: 1.8rem;
        //padding-right: 1.25rem;
        width: 30px;
        display: inline-block;
        text-align: center; 
        //margin-bottom: 6px;
    }
    
    .delivery {
        .icon {
            background-image: url('#{$images}/icons/ecommerce/icon-delivery.svg');
            //background-image: url('#{$images}/icons/ecommerce/icon-truck_grey.svg');
            background-repeat: no-repeat;
            background-position: center;
            //background-size: contain;
            //background-position: top;
            height: 30px;
            background-size: 30px;
            //margin-right: 1.875rem;
        }
    }

    .securepayment {
        .icon {
            background-image: url('#{$images}/icons/ecommerce/ico-sito-sicuro_white.svg');
            //background-image: url('#{$images}/icons/ecommerce/icon-secure_grey.svg');
            background-repeat: no-repeat;
            background-position: center;
            
            height: 30px;
            //margin-right: 1.875rem;
        }
    }

    .return {
        .icon {
            background-image: url('#{$images}/icons/ecommerce/icon-return.svg');
            //background-image: url('#{$images}/icons/ecommerce/icon-reso_grey.svg');
            background-repeat: no-repeat;
            background-position: center;
            //background-size: contain;
            //background-position: top;
            height: 30px;
            //margin-right: 1.875rem;
        }
    }

}

.emptyCart {
    min-height: 200px;
    text-align: center;
    .message {
        @include subText(); // chiedete a Cazzola
    }
}
 
// #region CARTLAYER 
 
.cartLayer {
    //height: 100%;
    display: flex;
    flex-direction: column;

    .cartLayer-container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    
    .emptyList {
        margin-right: 0;

        .cartLayer-empty {
            text-align: center; 
        }
    }
    
    .cartLayer-footer {
        padding: 0 $header-layer-padding-horizontal;

        .checkout-priceRecap{
            margin-top: 0;
        }
    }

    .discount-applied {
        display: none;
    }

    .cartProduct {
        border:  0;
        border-bottom: 1px solid  $grey;
        align-items: center;
        
        .cartProduct-selectQuantity {
            margin-bottom: 0;
        }
    }   
    
    button.primary.cmp_button {
        max-width: none !important;
        margin-bottom: 30px;
        margin-top: 10px;
    }

    .header_layer-title {
        text-align: left;
        display: flex;
        align-items: flex-end;
        text-transform: none;
        padding: 0 15px;
         .title-text {
            color: $black;
            font-family: Raleway;
            font-size: 2rem;
            font-weight: 700;
            text-transform: none;
            margin-right: 15px;
         }
         .title-count {
            text-transform: none;
            font-family: Raleway;
            font-size: 1rem;
            font-weight: 700;
            text-transform: none;
         }
    }
    .header_btn-closeLayer {
        top: 31px;
    }

    .cartProduct-name {
        font-size: .875rem;
        line-height: normal;

        .SKU {
            font-size: .63rem;
        }
    }
    .cartProduct-prices {
        line-height: normal;
        .price {
            font-size: .875rem;
            line-height: normal;
            display: block;
        }
        .price--strikethrough {
            font-size: .75rem;
            line-height: normal;
            margin-right: 0;
        }
    }

    .selected-quantity {
        font-size: .875rem;
        line-height: normal;
        height: auto;
    }

    .label-qnt {
        font-size: .63rem;
        font-weight: 400;
    }
    

    .cartProduct-details, .cartProduct-image, .cartProduct-name, .cartProduct-prices, .cartProduct-selectQuantity {
        margin-bottom: 15px;
    }

    .cartProduct-delete {
        margin-bottom: 0;
        line-height: normal;
        margin-left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;

        .icon {
            font-size: 12px;
        }
        > span {
            font-size: .63rem; 
            margin-right: 16px;
            font-weight: 400;
        }

        button {
            padding: 0;
        }
    }

    .checkout-priceRecap {
        border-top: 1px solid $black;
        padding-top: 15px;
        font-weight: 700;
        text-transform: uppercase;

        .price {
            text-transform: none;
        }

        .price.price--discounted{
            font-size: 1.25rem;
        }
    }

    .checkout-information {

        li {
            margin-bottom: 0;

            .label {
                font-size: 0.7rem;
            }
        }
        @media (max-width: $tablet-P) {
            display: none;
        }
    }
 
}
// #endregion
.ecommerce_relatedItems {
    h2 {
        margin-bottom: 0 !important;
    }

    .product-link__buy button.btn_addToCart {
        @media (max-width: 500px) {
            align-items: center;
            padding-left: 5px;
            padding-top: 3px;
        }
    }
}

.related-bundle{
    .slick-track { 
        margin: 0;
    }

    .prices {
        display: flex;
        align-items: baseline;
        @media (max-width: 991px) {
            justify-content: center;
        }
         > div {
            margin-right: 5px;
         }

         .price--discounted {
            font-size: 1.25rem !important;
         }
    }

    .product-link__buy {
        button {
            margin-top: 10px;
            .icon {
                margin-left: 10px;
            }
        }
    }
}

.younited-container {
    height: auto;
    width: 100%;
    display: inline-block;
    padding-left: 21px;
    margin-top: 14px;
}

.cart-icons{
    width: 100%;
    height: 45px;
}